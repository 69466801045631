import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, Layout, Seo, TextContent } from "../../components"
import * as textStyles from "../../components/text-content.module.scss"

export default function WieIsFreija({ data }) {
  var heroImage = getImage(data.file)
  var title = "Gehooroplossingen"

  return (
    <Layout site="audio" page={`/diensten`}>
      <Seo title={title} shop="audio" />
      <Hero image={heroImage} site="audio">
        <div>Gehoor</div>
        <div>Oplossingen</div>
      </Hero>
      <TextContent title={title} span="Uw weg naar beter horen!">
        <h4>Hoe ga je te werk: Eenvoudig in 5 stappen!</h4>
        <h5>1. Gratis gehoortest</h5>
        <p className={textStyles.paragraph}>
          Kom even langs voor en gehoortest. Dit duurt amper 15 minuten, is
          vrijblijvend en kosteloos. Aan de hand van gedetailleerde testen wordt
          je gehoor in kaart gebracht. Je kan hiervoor telefonisch een afspraak
          maken (0474/358313), of je aanvraag sturen via onze contactpagina.
          Wens je medisch advies, dan wordt je doorverwezen naar een NKO-arts.
          Met een NKO-voorschrift kan er een gratis proef opgestart.
        </p>
        <h5>2. Hooroplossing op maat</h5>
        <p className={textStyles.paragraph}>
          Tijdens een persoonlijk gesprek (intake), overlopen we je gehoor,
          levensstijl en persoonlijke behoeften. Aan de hand hiervan wordt een
          hoor-oplossing op uw maat voorgesteld, met oog voor esthetiek en
          maximaal draag- & hoorcomfort.
        </p>
        <h5>3. Gratis proefperiode</h5>
        <p className={textStyles.paragraph}>
          Hoewel de proefperiode wettelijk minimaal 2 weken voorgeschreven
          wordt, nemen wij, bij Wimâ€™s Hoorcenter, Ã©cht alle nodige tijd voor
          tussentijdse regelingen. Daarnaast kan ook een vergelijkende proef met
          een ander set apparaatjes voorgesteld worden. De proefperiode is
          steeds vrijblijvend en gratis, dankzij het NKO-voorschrift.
        </p>
        <h5>4. 100% tevredenheidsgarantie</h5>
        <p className={textStyles.paragraph}>
          Gezien we in de eerste weken vaak samenzitten, worden uw ervaringen
          regelmatig geëvalueerd. Op basis van uw feedback, komen we tot een
          fijnregeling. Op die manier kom je écht tot een perfect passende en
          optimaal functionerende oplossing. Daar je gehoor kan evolueren, kan
          je blijven rekenen op Wim voor een periodieke check-up en extra
          regeling.
        </p>
        <h5>5. Tussenkomst aanvragen</h5>
        <p className={textStyles.paragraph}>
          Kom je in aanmerking voor een financiële tussenkomst van uw
          ziekenfonds? Dan maken we het je nóg gemakkelijker ! Zo werken we
          steeds in een derde-betalers-systeem: Hierdoor betaal je nooit het
          totale bedrag, doch slechts jouw eigen bijdrage. De aanvraag voor
          tussenkomst wordt helemaal door ons verzorgd. Wij regelen de aanvraag
          voor tussenkomst en je hoeft je hierover niet te bekommeren. Hierdoor
          slaap je écht op je twee oren!
        </p>
      </TextContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "gehooroplossingen-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
